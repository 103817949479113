// Import necessary modules from react-router-dom
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import WorkLocation from "./WorkLocation";
import "./App.css";

function App() {
  const [section, setSection] = useState(0);
  const [mobileScreen, setMobileScreen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function isMobileOrTablet() {
      // Check if the user agent string contains any of the common mobile or tablet keywords
      return /Mobi|Android|iPhone|iPod|iPad|Windows Phone|Tablet/i.test(
        navigator.userAgent
      );
    }
    setMobileScreen(isMobileOrTablet());
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Default route redirects to /Work-Location */}
          <Route path="/" element={<Navigate to="/work-location-declaration" replace />} />
          
          {/* Route for /Work-Location */}
          <Route 
            path="/work-location-declaration" 
            element={
              <WorkLocation 
                loading={loading} 
                section={section} 
                mobileScreen={mobileScreen} 
                setSection={setSection} 
              />
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
