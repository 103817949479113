import React from 'react';
import LastPage from "./components/sections/LastPage";
import LandingPage from "./components/sections/LandingPage";
import "./components/sections/Sections.scss";
import Form from "./components/sections/Form";
import Loader from "./components/Loader/Loader";

const WorkLocation = ({loading, section, setSection, mobileScreen}) => {
  return (
    <div>
       {loading && <Loader />}
      {!loading && (
        <>
          {section === 0 && (
            <LandingPage
              mobileScreen={mobileScreen}
              section={section}
              setSection={setSection}
            />
          )}
          {section > 0 && section < 11 && (
            <Form
              mobileScreen={mobileScreen}
              section={section}
              setSection={setSection}
            />
          )}

          {section === 11 && <LastPage mobileScreen={mobileScreen} />}
        </>
      )}
    </div>
  )
}

export default WorkLocation
